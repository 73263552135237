/* eslint-disable no-underscore-dangle */
/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Typography, Button, Divider, Grid, FormGroup, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { pink } from '@mui/material/colors';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.assetList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import ImportComponent from '../../components/SharedComponents/ImportComponent/ImportComponent';
import AddEditAsset from '../AddEditAsset/AddEditAsset';
import {
    assetDataFilterBasedOnDeviceFeature,
    isEmpty,
    getAppUserSettings,
    convertDateTime,
    getHeaderKeysForPost,
    downloadFile,
    getUserSettingTimeZone,
    getDistanceStringLong,
    getTimeFormatAsperUserSettting,
    getParamValue,
    savePage,
    getSavedPage,
    removeSavedPage,
    getValueFromObjectByKey,
    convertUnitValueAsPerUserSetting,
    getDistanceString,
    getSpeedUnitString,
    getTemperatureUnitString,
    getAssetStatusStartDate,
    getPermissionValue,
    hasFeature,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    isBoolean,
} from '../../helper-classes/utility-functions';
import {
    getSplittedHeader,
    getInputStatusCell,
    getOilCell,
    getSeatBeltCell,
    getTirePressureCell,
    getFuelCell, getTemperatureCell,
    getBatteryStatusIcon,
    getSignalStrengthIcon,
    getFridgeStatusCell,
} from './../../helper-classes/utility-react-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import { getLandmarkOrLocation, getAssetStatusTextColor } from '../../util/map_utils';
import { getURL, getRequestPayload } from './epics.assetList';
import AppLoader from '../../components/AppLoader';
import analytics from '../../analytics/index';
import { ASSET_LIST_PREF_KEY } from './constants.assetList';
import { RECORDS_PER_PAGE } from '../../constants/Config';

export type Props = {
    classes: Object,
    isUpdating: boolean,
    fetchAssetsList: Function,
    resetTable: Function,
    location: Object,
    match: {
        path: any,
    },
    history: {
        push: Function,
    },
    deviceFeature: Object,
};

export type State = {
    tableData: {
        listHeaders: Array<{ show: boolean }>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
        isEditable: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    assetFilters: Object,
    selectedOption: Object,
    loading: boolean,
    openExport: boolean,
    openImport: boolean,
    isAddEdit: boolean,
    showListing: boolean,
};

const themeStyles = () => ({
    checked: { color: pink },
    label: { color: '#696969' },
    textContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        maxWidth: 75,
    },
    hide: { display: 'none !important' },
    show: { display: 'block !important' },
});

class AssetList extends Component<Props, State> {
    button: Array<Object>;
    advanceFilterData: any;
    assetFiltersToSend: any;
    assetFilterList: Object;
    search: string;
    id: string;
    addUrl: string;
    editUrl: string;
    userPreferences: any;

    constructor(props: Props) {
        super(props);
        this.addUrl = '/assets/add';
        this.editUrl = '/assets/edit/:id';
        this.id = getParamValue(this.props, 'id');
        const pageData = this.initPage();
        this.state = pageData.state;
        this.advanceFilterData = pageData.advanceFilterData;
        this.search = pageData.search;
        this.assetFilterList = pageData.assetFilterList;
        this.assetFiltersToSend = this.getFilterParams(this.assetFilterList);
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        if (!this.state.isAddEdit) {
            getUserPreference(this, ASSET_LIST_PREF_KEY);
        }
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        const { tableData } = this.state;
        const { assets, totalAssets } = nextprops;

        if (assets.length > 0) {
            let userSettings = getAppUserSettings();
            if (isEmpty(userSettings)) {
                userSettings = JSON.parse('{"app.settings": {"value": {"timeDisplay": "24h"}}}');
            }
            const timeDisplay = (userSettings && userSettings['app.settings'] && userSettings['app.settings'].value && userSettings['app.settings'].value.timeDisplay) ? userSettings['app.settings'].value.timeDisplay : '';
            const timeFormat = timeDisplay === '24h' ? 'MM/DD/YYYY H:mm' : 'MM/DD/YYYY h:mm A';
            const formattedAssetList = assets.map((item => ({
                ...item,
                tags: item.tags ? item.tags.join(', ') : '',
                lastEventDate: item.lastEventDate ? convertDateTime(item.lastEventDate, timeFormat) : '',
                speed: this.getSpeed(item),
                intVolt: item.intVolt,
                extVolt: item.extVolt ? parseFloat(item.extVolt).toFixed(1) : item.extVolt,
                odometer:
                    convertUnitValueAsPerUserSetting(parseFloat(item.odometer || 0)).toFixed(1),
                engineHours: item.engineHours > 0 ? item.engineHours.toFixed(1) : item.engineHours,
                statusStartDate: isAssetNonReporting(item)
                    ? getTimeStr(getTimeDiff(item.lastEventDate, new Date()))
                    : getAssetStatusStartDate(item),
                dashcamIdentifier: item.dashcamIdentifier ? item.dashcamIdentifier : '--',
            })));
            tableData.listHeaders = this.getListHeader();
            tableData.listData = formattedAssetList;
            tableData.totalRecords = totalAssets;
            this.setState({ tableData });
        } else if (!this.state.isAddEdit) {
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }

        if (this.props.deviceFeature) {
            const header = this.getListHeader();
            tableData.listHeaders = header;
            this.setState({ tableData });
        }
    }

    componentDidUpdate() {
        let { isAddEdit } = this.state;
        const { match } = this.props;

        if (isAddEdit && match.path === '/assets') {
            isAddEdit = false;
            const savedPage = getSavedPage();
            if (savedPage) {
                const pageData = this.initPage();
                this.updateSavedPageState(pageData.state);
                this.advanceFilterData = pageData.advanceFilterData;
                this.search = pageData.search;
                this.assetFilterList = pageData.assetFilterList;
            } else {
                getUserPreference(this, ASSET_LIST_PREF_KEY);
            }
            removeSavedPage();
            this.updateEditState(isAddEdit);
        } else if (!isAddEdit &&
            (match.path === this.editUrl || match.path === this.addUrl)) {
            isAddEdit = true;
            this.updateEditState(isAddEdit);
        }
    }

    updateSavedPageState = (state) => {
        // call this funciton from component did update only to set the saved page
        this.setState({ ...state }, () => {
            getUserPreference(this, ASSET_LIST_PREF_KEY);
        });
    }

    initPage = () => {
        let isAddEdit = false;
        const savedPage = getSavedPage();
        if (this.id || this.props.match.path === this.addUrl) {
            isAddEdit = true;
        } else removeSavedPage();

        const savedAssetFilter = savedPage && savedPage.assetFilterList ?
            savedPage.assetFilterList : null;

        const data = {
            state: {
                tableData: {
                    listHeaders: this.getListHeader(),
                    listData: [],
                    totalRecords: 0,
                    ischeckBox: false,
                    isGroup: false,
                    isEditable: true,
                },
                rowsPerPage: getValueFromObjectByKey(savedPage, 'rowsPerPage', RECORDS_PER_PAGE),
                pageNumber: getValueFromObjectByKey(savedPage, 'pageNumber', 0),
                order: getValueFromObjectByKey(savedPage, 'order', 'asc'),
                orderBy: getValueFromObjectByKey(savedPage, 'orderBy', 'name'),
                assetFilters: {
                    status: getValueFromObjectByKey(savedAssetFilter, 'status', {
                        moving: false, stopped: false, idle: false,
                    }),
                    reportingStatus: getValueFromObjectByKey(savedAssetFilter, 'reportingStatus', {
                        'non-reporting': false, reporting: false,
                    }),
                    batteryStatus: getValueFromObjectByKey(savedAssetFilter, 'batteryStatus', { weak: false, good: false }),
                    landmark: getValueFromObjectByKey(savedAssetFilter, 'landmark', { yes: false, no: false }),
                    inputStatus: getValueFromObjectByKey(savedAssetFilter, 'inputStatus', { on: false, off: false }),
                    assetLinked: getValueFromObjectByKey(savedAssetFilter, 'assetLinked', { yes: false, no: false }),
                },
                selectedOption: {
                    lable: 'CSV',
                    value: 'csv',
                    key: 'CSV',
                },
                loading: false,
                openExport: false,
                openImport: false,
                isAddEdit,
                showListing: false,
            },
            assetFilterList: {
                status: getValueFromObjectByKey(savedAssetFilter, 'status', {
                    moving: false, stopped: false, idle: false,
                }),
                reportingStatus: getValueFromObjectByKey(savedAssetFilter, 'reportingStatus', {
                    'non-reporting': false, reporting: false,
                }),
                batteryStatus: getValueFromObjectByKey(savedAssetFilter, 'batteryStatus', { weak: false, good: false }),
                landmark: getValueFromObjectByKey(savedAssetFilter, 'landmark', { yes: false, no: false }),
                inputStatus: getValueFromObjectByKey(savedAssetFilter, 'inputStatus', { on: false, off: false }),
                assetLinked: getValueFromObjectByKey(savedAssetFilter, 'assetLinked', { yes: false, no: false }),
                cargoLoaded: getValueFromObjectByKey(savedAssetFilter, 'cargoLoaded', { loaded: false, Unloaded: false }),
            },
            advanceFilterData: getValueFromObjectByKey(savedPage, 'advanceFilterData', []),
            search: getValueFromObjectByKey(savedPage, 'searchKey', ''),
        };
        return data;
    }

    updateEditState = isAddEdit => this.setState({ isAddEdit });

    getSpeed = (data: any) => {
        if (data && data.status && data.status.toLowerCase() === 'stopped') {
            return 0;
        }
        return Math.round(convertUnitValueAsPerUserSetting(data.speed || 0));
    };

    setCustomBackUrl = () => {
        const pageConfig = {
            url: '/assets',
            pageNumber: this.state.pageNumber,
            rowsPerPage: this.state.rowsPerPage,
            order: this.state.order,
            orderBy: this.state.orderBy,
            searchKey: this.search,
            assetFilterList: this.assetFilterList,
            advanceFilterData: this.advanceFilterData,
        };
        savePage(pageConfig);
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            {
                name: 'Name',
                path: 'assets',
                ...getColumnPreferences('name', preferences),
                customMarkup: (d) => {
                    const link = (
                        d.deviceId ? <Link to={`/home?assetId=${d.id}&zoom=true`} style={{ color: 'inherit' }} onClick={() => this.setCustomBackUrl()}> {d.name} </Link> : d.name
                    );
                    return link;
                },
                keyName: 'id',
            },
            { deviceSerialNumber: 'Serial Number', ...getColumnPreferences('deviceSerialNumber', preferences) },
            { typeName: 'Asset Type', ...getColumnPreferences('typeName', preferences) },
            { address: 'Address', ...getColumnPreferences('address', preferences) },
            { city: 'City', ...getColumnPreferences('city', preferences) },
            { stateOrProvince: 'State', ...getColumnPreferences('stateOrProvince', preferences) },
            { zipCode: 'Zip Code', ...getColumnPreferences('zipCode', preferences) },
            { driverName: 'Driver', ...getColumnPreferences('driverName', preferences) },
            {
                engineHours: 'Engine Hours',
                ...getColumnPreferences('engineHours', preferences),
                disableSort: true,
                customMarkup: d => parseFloat(d.engineHours || 0).toFixed(1),
            },
            {
                status: 'Status',
                ...getColumnPreferences('status', preferences),
                customMarkup: (d) => {
                    const statusTextColor = getAssetStatusTextColor(isAssetNonReporting(d)
                        ? { status: d.reportingStatus } : d);
                    return (
                        <Tooltip title={d.status} disableFocusListener>
                            <span style={{ color: [statusTextColor] }}>{d.status}</span>
                        </Tooltip>
                    );
                },
            },
            {
                reportingStatus: 'Reporting Status',
                ...getColumnPreferences('reportingStatus', preferences),
                disableSort: true,
                customMarkup: d => (d.reportingStatus),
            },
            { batteryStatus: 'External Battery Status', ...getColumnPreferences('batteryStatus', preferences), disableSort: true },
            { deviceBatteryStatus: 'Device Battery Status', ...getColumnPreferences('deviceBatteryStatus', preferences), disableSort: true },
            {
                extVolt: 'Asset Battery',
                ...getColumnPreferences('extVolt', preferences),
                customMarkup: d => (getBatteryStatusIcon(d.extVolt)),
            },
            {
                assetGroupName: 'Group Name',
                ...getColumnPreferences('assetGroupName', preferences),
                customMarkup: (d) => {
                    let link = d.assetGroupName;
                    if (getPermissionValue('Asset Groups') !== 'None' && d.assetGroupId) {
                        link = (
                            <Tooltip title={d.assetGroupName || ''} disableFocusListener>
                                <Link
                                    to={`asset-groups/${d.assetGroupId}`}
                                    style={{ color: 'inherit' }}
                                    onClick={() => this.setCustomBackUrl()}
                                >
                                    {d.assetGroupName}
                                </Link>
                            </Tooltip>
                        );
                    }
                    return link;
                },
            },
            { intVolt: 'Tracker Battery', ...getColumnPreferences('intVolt', preferences) },
            { lastEventDate: 'Last Report Date', ...getColumnPreferences('lastEventDate', preferences) },
            { locationOrLandmark: 'Location', ...getColumnPreferences('locationOrLandmark', preferences), customMarkup: d => getLandmarkOrLocation(d) },
            { latitude: 'Latitude', ...getColumnPreferences('latitude', preferences), disableSort: true },
            { longitude: 'Longitude', ...getColumnPreferences('longitude', preferences), disableSort: true },
            { make: 'Make', ...getColumnPreferences('make', preferences) },
            { model: 'Model', ...getColumnPreferences('model', preferences) },
            { vin: 'VIN', ...getColumnPreferences('vin', preferences) },
            { year: 'Year', ...getColumnPreferences('year', preferences) },
            {
                ...getSplittedHeader(`Odometer / Total Mileage (${getDistanceString()})`, '', 'odometer'),
                ...getColumnPreferences('odometer', preferences),
                disableSort: true,
            },
            { ...getSplittedHeader(`Speed (${getSpeedUnitString()})`, '', 'speed'), ...getColumnPreferences('speed', preferences) },
            { statusStartDate: 'Status Duration', ...getColumnPreferences('statusStartDate', preferences) },
            { tags: 'Tags', ...getColumnPreferences('tags', preferences), disableSort: true },
            { direction: 'Heading', ...getColumnPreferences('direction', preferences) },
            {
                inputStatus: 'Input Status',
                ...getColumnPreferences('inputStatus', preferences),
                customMarkup: data => getInputStatusCell(data, { alignItems: 'center', justifyContent: 'center' }),
                disableSort: true,
            },
            {
                cargoLoaded: 'Cargo Status',
                ...getColumnPreferences('cargoLoaded', preferences),
                disableSort: true,
                customMarkup: data => {
                    if (isBoolean(data.cargoLoaded)) {
                        return data.cargoLoaded ? 'Loaded' : 'Unloaded'
                    } else return '';
                },
            },
            {
                powerConnected: 'Tractor Power',
                ...getColumnPreferences('powerConnected', preferences),
                disableSort: true,
                customMarkup: data => {
                    if (isBoolean(data.powerConnected)) {
                        return data.powerConnected ? 'Connected' : 'Disconnected'
                    } else return '';
                },
            },
            {
                solarVoltage: 'Solar Voltage',
                ...getColumnPreferences('solarVoltage', preferences),
            },
            {
                cargoTemperature: `Temperature (${getTemperatureUnitString()})`,
                ...getColumnPreferences(
                    'cargoTemperature',
                    preferences,
                    {
                        show: assetDataFilterBasedOnDeviceFeature('temperature'),
                    },
                ),
                showInDropDown: false,
                customMarkup: data => getTemperatureCell(data.cargoTemperature),
            },
            {
                oilLife: 'Oil Life',
                ...getColumnPreferences(
                    'oilLife',
                    preferences,
                    {
                        show: assetDataFilterBasedOnDeviceFeature('oilLifeRemaining'),
                    },
                ),
                showInDropDown: false,
                customMarkup: data => getOilCell(data, { alignItems: 'center', justifyContent: 'center' }),
                disableSort: true,
            },
            {
                seatBeltStatus: 'Seatbelt Status',
                ...getColumnPreferences(
                    'seatBeltStatus',
                    preferences,
                    {
                        show: assetDataFilterBasedOnDeviceFeature('seatBeltStatus'),
                    },
                ),
                showInDropDown: false,
                customMarkup: data => getSeatBeltCell(data, { alignItems: 'center', justifyContent: 'center' }),
                disableSort: true,
            },
            {
                tirePressure: 'Tire Pressure',
                ...getColumnPreferences(
                    'tirePressure',
                    preferences,
                    {
                        show: assetDataFilterBasedOnDeviceFeature('tirePressure'),
                    },
                ),
                showInDropDown: false,
                customMarkup: data => getTirePressureCell(data, { alignItems: 'center', justifyContent: 'center', lineHeight: 1 }),
                disableSort: true,
            },
            {
                fuelLevelText: 'Fuel Level',
                ...getColumnPreferences(
                    'fuelLevelText',
                    preferences,
                    {
                        show: assetDataFilterBasedOnDeviceFeature('fuelLevelStatus'),
                    },
                ),
                showInDropDown: false,
                customMarkup: data => getFuelCell(data, { alignItems: 'center', justifyContent: 'center', lineHeight: 1 }),
                disableSort: true,
            },
            { lastEvent: 'Last Event', ...getColumnPreferences('lastEvent', preferences) },
            {
                dashcamIdentifier: 'CamCoach',
                ...getColumnPreferences(
                    'dashcamIdentifier',
                    preferences,
                    {
                        show: !(hasFeature('fleetLightMetricsDashcam') || hasFeature('fleetDashcam')) ? false : undefined,
                    },
                ),
                showInDropDown: hasFeature('fleetLightMetricsDashcam') || hasFeature('fleetDashcam'),
            },
            {
                signalStrength: 'Cell Strength',
                ...getColumnPreferences('signalStrength', preferences),
                customMarkup: d => (getSignalStrengthIcon(d.signalStrengthStatus)),
            },
            {
                fridgeStatus: 'Fridge Status',
                ...getColumnPreferences(
                    'fridgeStatus',
                    preferences,
                    {
                        show: hasFeature('fleetTemperature'),
                    },
                ),
                customMarkup: data => (getFridgeStatusCell(data)),
                disableSort: true,
                showInDropDown: false,
            },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getData = () => {
        this.props
            .fetchAssetsList(
                this.state.pageNumber,
                this.state.rowsPerPage || RECORDS_PER_PAGE,
                this.state.order,
                this.state.orderBy,
                this.getFilterData(),
            );
        this.setState({ showListing: true });
    }

    addClick = () => {
        this.props.history.push(`${this.props.location.pathname}/add`);
    }

    getFilterData = () => ({
        assetFiltersToSend: this.assetFiltersToSend,
        search: this.search || '',
        advanceSearchParam: this.advanceFilterData,
    })

    loadNextPage = (rows, value, order, orderBy) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) saveUserPreference(this, ASSET_LIST_PREF_KEY);
        });

        this.props.fetchAssetsList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchAssetsList(
            page.pageNumber,
            page.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );

        this.setState({
            order,
            orderBy,
            rowsPerPage: page.rowsPerPage,
            pageNumber: page.pageNumber,
        }, () => saveUserPreference(this, ASSET_LIST_PREF_KEY));
        const params = {
            feature: 'FLEET_Asset',
            sortColumn: orderBy,
        };
        analytics.track('ASSET_SORT', params);
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchAssetsList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: filter.rowsPerPage,
            pageNumber: filter.pageNumber,
        });
    }

    applyAssetFilters = (event, key) => {
        const targetElement = event && event.target;
        this.assetFilterList[key][targetElement.value] = targetElement.checked;
        this.assetFiltersToSend = this.getFilterParams(this.assetFilterList);
        this.setState(
            {
                assetFilters: this.assetFilterList,
                pageNumber: 0,
            },
            () => this.props.fetchAssetsList(
                0,
                this.state.rowsPerPage,
                this.state.order,
                this.state.orderBy,
                this.getFilterData(),
            ),
        );
    };

    clearFilters = () => {
        const statusAssetFilterList = Object.keys(this.state.assetFilters.status)
            .map(item => (this.state.assetFilters.status[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const reportingStatusAssetFilterList = Object.keys(this.state.assetFilters.reportingStatus)
            .map(item => (this.state.assetFilters.reportingStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const batteryStatusAssetFilterList = Object.keys(this.state.assetFilters.batteryStatus)
            .map(item => (this.state.assetFilters.batteryStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const landmarkAssetFilterList = Object.keys(this.state.assetFilters.landmark)
            .map(item => (this.state.assetFilters.landmark[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const inputStatusAssetFilterList = Object.keys(this.state.assetFilters.inputStatus)
            .map(item => (this.state.assetFilters.inputStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const assetLinkedDeviceFilterList = Object.keys(this.state.assetFilters.assetLinked)
            .map(item => (this.state.assetFilters.assetLinked[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const cargoLoadedFilterList = Object.keys(this.state.assetFilters.cargoLoaded)
            .map(item => (this.state.assetFilters.cargoLoaded[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);

        this.assetFilterList.status.moving = false;
        this.assetFilterList.status.stopped = false;
        this.assetFilterList.status.idle = false;
        this.assetFilterList.reportingStatus['non-reporting'] = false;
        this.assetFilterList.reportingStatus.reporting = false;
        this.assetFilterList.batteryStatus.weak = false;
        this.assetFilterList.batteryStatus.good = false;
        this.assetFilterList.inputStatus.on = false;
        this.assetFilterList.inputStatus.off = false;
        this.assetFilterList.landmark.yes = false;
        this.assetFilterList.landmark.no = false;
        this.assetFilterList.assetLinked.yes = false;
        this.assetFilterList.assetLinked.no = false;
        this.assetFilterList.cargoLoaded.loaded = false;
        this.assetFilterList.cargoLoaded.unloaded = false;

        this.setState({ assetFilters: this.assetFilterList, pageNumber: 0 }, () => {
            this.assetFiltersToSend = this.getFilterParams(this.assetFilterList);
            if (statusAssetFilterList.length > 0 || reportingStatusAssetFilterList.length > 0
                || batteryStatusAssetFilterList.length > 0 || inputStatusAssetFilterList.length > 0
                || landmarkAssetFilterList.length > 0 || assetLinkedDeviceFilterList.length > 0 ||
                cargoLoadedFilterList.length > 0) {
                this.props.fetchAssetsList(
                    this.state.pageNumber,
                    this.state.rowsPerPage,
                    this.state.order,
                    this.state.orderBy,
                    this.getFilterData(),
                );
            }
        });
    };

    getFilterParams = (assetFilters) => {
        const statusAssetFilterList = Object.keys(assetFilters.status)
            .map(item => (assetFilters.status[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const reportingStatusAssetFilterList = Object.keys(assetFilters.reportingStatus)
            .map(item => (assetFilters.reportingStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const batteryStatusAssetFilterList = Object.keys(assetFilters.batteryStatus)
            .map(item => (assetFilters.batteryStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const landmarkAssetFilterList = Object.keys(assetFilters.landmark)
            .map(item => (assetFilters.landmark[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const inputStatusAssetFilterList = Object.keys(assetFilters.inputStatus)
            .map(item => (assetFilters.inputStatus[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const assetLinkedDeviceFilterList = Object.keys(assetFilters.assetLinked)
            .map(item => (assetFilters.assetLinked[item] ? item.toLowerCase() : '')).filter(item => item.length > 0);
        const cargoLoadedFilterList = [assetFilters.cargoLoaded.loaded ? 'true' : '',
            assetFilters.cargoLoaded.Unloaded ? 'false' : ''].filter(item => item.length > 0);

        this.assetFiltersToSend = [];

        if (statusAssetFilterList.length > 0) {
            this.assetFiltersToSend.push(['status', `eq:${statusAssetFilterList.join(',')}`]);
        }
        if (reportingStatusAssetFilterList.length > 0) {
            this.assetFiltersToSend.push(['reportingStatus', `eq:${reportingStatusAssetFilterList.join(',')}`]);
        }
        if (batteryStatusAssetFilterList.length > 0) {
            this.assetFiltersToSend.push(['batterystatus', `eq:${batteryStatusAssetFilterList.join(',')}`]);
        }
        if (landmarkAssetFilterList.length > 0) {
            this.assetFiltersToSend.push(['landmarkName', `exists:${landmarkAssetFilterList.join(',')}`]);
        }
        if (inputStatusAssetFilterList.length > 0) {
            this.assetFiltersToSend.push(['inputStatus', `eq:${inputStatusAssetFilterList.join(',')}`]);
        }
        if (assetLinkedDeviceFilterList.length > 0) {
            this.assetFiltersToSend.push(['deviceId', `exists:${assetLinkedDeviceFilterList.join(',')}`]);
        }
        if (cargoLoadedFilterList.length > 0) {
            this.assetFiltersToSend.push(['cargoLoaded', `eq:${cargoLoadedFilterList.join(',')}`]);
        }

        return this.assetFiltersToSend;
    }

    advanceFilter = (data) => {
        const filterData = [];
        Object.keys(data).forEach((d) => {
            if (d && data[d]) filterData.push([d, data[d]]);
        });

        if (filterData.length > 0) this.advanceFilterData = filterData;
        else this.advanceFilterData = [];

        this.props.fetchAssetsList(
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    refreshList = (page) => {
        this.props.fetchAssetsList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'filter') {
            return this.renderAssetFilters();
        } else if (action === 'add') {
            this.addClick();
        } else if (action === 'export') {
            this.openExport(true);
            analytics.track('EXPORT', { feature: 'FLEET_Asset' });
        } else if (action === 'import') {
            this.openImport(true);
            analytics.track('IMPORT', { feature: 'FLEET_Asset' });
        }
        return true;
    };

    clickRow = (n: any) => this.props.history.push(`/assets/edit/${n.id}`);

    renderAssetFilters = () => {
        const { classes } = this.props;
        const styles = {
            button: { flex: 'auto', opacity: '.87', color: '#007aff' },
            subheading: {
                fontSize: 15,
                opacity: '.87',
                padding: '10px 20px 0 20px',
                lineHeight: 1.75,
            },
            title: {
                flex: 'auto',
                fontSize: '20px',
                opacity: '.87',
                padding: '20px 20px 10px 20px',
            },
            filterCheckboxChecked: {
                color: '#007aff',
                marginLeft: '40px',
            },
            filterCheckboxUnchecked: {
                color: '#696969',
                marginLeft: '40px',
            },
        };

        return (
            <div style={{ padding: 20, flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        color="primary"
                        style={styles.button}
                        onClick={this.clearFilters}
                    >
                        CLEAR
                    </Button>
                </div>
                <Divider />
                <Grid item xs={7} key="assetStatus">
                    <FormGroup>
                        <Typography variant="body1" id="status" style={styles.subheading}>Status</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="moving"
                                    value="moving"
                                    checked={this.assetFilterList.status.moving}
                                    onChange={e => this.applyAssetFilters(e, 'status')}
                                    style={this.assetFilterList.status.moving ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="movingStatus"
                            label="Moving"
                            classes={{ label: `${this.assetFilterList.status.moving ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="stopped"
                                    value="stopped"
                                    checked={this.assetFilterList.status.stopped}
                                    onChange={e => this.applyAssetFilters(e, 'status')}
                                    style={this.assetFilterList.status.stopped ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="stoppedStatus"
                            label="Stopped"
                            classes={{ label: `${this.assetFilterList.status.stopped ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="idle"
                                    value="idle"
                                    checked={this.assetFilterList.status.idle}
                                    onChange={e => this.applyAssetFilters(e, 'status')}
                                    style={this.assetFilterList.status.idle ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="idleStatus"
                            label="Idle"
                            classes={{ label: `${this.assetFilterList.status.idle ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={7} key="assetReportingStatus">
                    <FormGroup>
                        <Typography variant="body1" id="reportingStatus" style={styles.subheading}>Reporting Status</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="reporting"
                                    value="reporting"
                                    checked={this.assetFilterList.reportingStatus.reporting}
                                    onChange={e => this.applyAssetFilters(e, 'reportingStatus')}
                                    style={this.assetFilterList.reportingStatus.reporting ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="reportingStatus"
                            label="Reporting"
                            classes={{ label: `${this.assetFilterList.reportingStatus.reporting ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="nonReporting"
                                    value="non-reporting"
                                    checked={this.assetFilterList.reportingStatus['non-reporting']}
                                    onChange={e => this.applyAssetFilters(e, 'reportingStatus')}
                                    style={this.assetFilterList.reportingStatus['non-reporting'] ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="nonReportingStatus"
                            label="Non-reporting"
                            classes={{ label: `${this.assetFilterList.reportingStatus['non-reporting'] ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={7} key="batteryStatus">
                    <FormGroup>
                        <Typography
                            variant="body1"
                            id="batteryStatus"
                            style={styles.subheading}
                        >
                            Battery Status
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="weak"
                                    value="weak"
                                    checked={this.assetFilterList.batteryStatus.weak}
                                    onChange={e => this.applyAssetFilters(e, 'batteryStatus')}
                                    style={this.assetFilterList.batteryStatus.weak ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="batteryStatusWeak"
                            label="Weak"
                            classes={{
                                label: `${this.assetFilterList.batteryStatus.weak ? classes.checked : classes.label}`,
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="good"
                                    value="good"
                                    checked={this.assetFilterList.batteryStatus.good}
                                    onChange={e => this.applyAssetFilters(e, 'batteryStatus')}
                                    style={this.assetFilterList.batteryStatus.good ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="batteryStatusGood"
                            label="Good"
                            classes={{
                                label: `${this.assetFilterList.batteryStatus.good ? classes.checked : classes.label}`,
                            }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={7} key="inputStatus">
                    <FormGroup>
                        <Typography variant="body1" id="inputStatus" style={styles.subheading}>Input Status</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="inputOn"
                                    value="on"
                                    checked={this.assetFilterList.inputStatus.on}
                                    onChange={e => this.applyAssetFilters(e, 'inputStatus')}
                                    style={this.assetFilterList.inputStatus.on ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="inputStatusOn"
                            label="Input On"
                            classes={{ label: `${this.assetFilterList.inputStatus.on ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="inputOff"
                                    value="off"
                                    checked={this.assetFilterList.inputStatus.off}
                                    onChange={e => this.applyAssetFilters(e, 'inputStatus')}
                                    style={this.assetFilterList.inputStatus.off ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="inputStatusOff"
                            label="Input Off"
                            classes={{ label: `${this.assetFilterList.inputStatus.off ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={12} key="landmarkStatus">
                    <FormGroup>
                        <Typography variant="body1" id="landmarkStatus" style={styles.subheading}>Landmark Status</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="landmarkStatusYes"
                                    value="yes"
                                    checked={this.assetFilterList.landmark.yes}
                                    onChange={e => this.applyAssetFilters(e, 'landmark')}
                                    style={this.assetFilterList.landmark.yes ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="landmarkStatusInside"
                            label="Inside Landmark"
                            classes={{ label: `${this.assetFilterList.landmark.yes ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="landmarkStatusNo"
                                    value="no"
                                    checked={this.assetFilterList.landmark.no}
                                    onChange={e => this.applyAssetFilters(e, 'landmark')}
                                    style={this.assetFilterList.landmark.no ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="landmarkStatusOutside"
                            label="Outside Landmark"
                            classes={{ label: `${this.assetFilterList.landmark.no ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={7} key="assetAssertion">
                    <FormGroup>
                        <Typography variant="body1" id="assetLinked" style={styles.subheading}>Asset Association</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="assetAssertionYes"
                                    value="yes"
                                    checked={this.assetFilterList.assetLinked.yes}
                                    onChange={e => this.applyAssetFilters(e, 'assetLinked')}
                                    style={this.assetFilterList.assetLinked.yes ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="assetAssertionLinked"
                            label="Linked"
                            classes={{ label: `${this.assetFilterList.assetLinked.yes ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="assetAssertionNo"
                                    value="no"
                                    checked={this.assetFilterList.assetLinked.no}
                                    onChange={e => this.applyAssetFilters(e, 'assetLinked')}
                                    style={this.assetFilterList.assetLinked.no ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="assetAssertionUnLinked"
                            label="Unlinked"
                            classes={{ label: `${this.assetFilterList.assetLinked.no ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={7} key="cargoLoaded">
                    <FormGroup>
                        <Typography variant="body1" id="cargoLoaded" style={styles.subheading}>Cargo Loaded</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="cargoLoaded"
                                    value="loaded"
                                    checked={this.assetFilterList.cargoLoaded.loaded}
                                    onChange={e => this.applyAssetFilters(e, 'cargoLoaded')}
                                    style={this.assetFilterList.cargoLoaded.loaded ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="cargoLoaded"
                            label="Loaded"
                            classes={{ label: `${this.assetFilterList.cargoLoaded.loaded ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="cargoUnloaded"
                                    value="Unloaded"
                                    checked={this.assetFilterList.cargoLoaded.Unloaded}
                                    onChange={e => this.applyAssetFilters(e, 'cargoLoaded')}
                                    style={this.assetFilterList.cargoLoaded.Unloaded ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="cargoUnloaded"
                            label="Unloaded"
                            classes={{ label: `${this.assetFilterList.cargoLoaded.Unloaded ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
            </div>
        );
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: ASSET_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: ASSET_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    dialogHandleClick = (type, value) => {
        if (type === 'show') this.setState({ selectedOption: value });
        else if (type === 'download') {
            const {
                tableData, selectedOption, order, orderBy,
            } = this.state;

            const filter = this.getFilterData();
            this.openExport(false);
            this.setState({ loading: true });
            const requestPayLoad = getRequestPayload({
                order,
                orderBy,
                pageNumber: 0,
                pageSize: tableData.totalRecords,
                filter,
            });

            const reportRequestPayload = {
                ...requestPayLoad,
                reportColumns: getHeaderKeysForPost(tableData.listHeaders),
                timeZone: getUserSettingTimeZone(),
                unitMeasurement: getDistanceStringLong(),
                timeFormat: getTimeFormatAsperUserSettting(),
            };

            for (let i = 0; i < reportRequestPayload.reportColumns.length; i += 1) {
                if (reportRequestPayload.reportColumns[i][0] === 'statusStartDate') {
                    reportRequestPayload.reportColumns[i][0] = 'statusDisplayDate';
                    break;
                }
            }
            downloadFile(
                getURL(),
                `Assets ${moment().format('YYYY-MM-DD HH-mm-ss')}.${selectedOption.value}`,
                { type: selectedOption.value },
                () => this.setState({ loading: false }),
                reportRequestPayload,
            );
        } else this.openExport(false);
    }

    openExport = openExport => this.setState({ openExport });
    openImport = openImport => this.setState({ openImport });
    closeImport = () => {
        this.setState({ openImport: false });
    }

    updateLoader = (loading) => {
        this.setState({ loading });
    }

    render() {
        const { classes, history } = this.props;
        const {
            loading,
            isAddEdit,
            openImport,
            selectedOption,
        } = this.state;
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        const CloneProps = { ...this.props, classes: {} };

        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <MiniDrawer redirectTo={history.push}>
                    {isAddEdit && <AddEditAsset {...CloneProps} />}
                    <div className={!isAddEdit && !openImport ? '' : classes.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                resetTable={this.props.resetTable}
                                isUpdating={this.props.isUpdating}
                                sortTableColumn={this.sortTableColumn}
                                showHideColumn={this.showHideColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                tableTitle="Assets"
                                advanceFilter={data => this.advanceFilter(data)}
                                ListSearch={this.ListSearch}
                                filtersApplied={this.assetFiltersToSend.length > 0}
                                redirectTo={history.push}
                                rearrangeColumns={this.rearrangeColumns}
                                tableButtons={[
                                    'Advanced Search',
                                    'Import',
                                    'Export',
                                    'Refresh',
                                    'Filter List',
                                    'Add Items',
                                ]}
                                resizableHeader
                                clickRow={this.clickRow}
                                pathName={this.props.location.pathname}
                                dialogboxInfo={{
                                    title: `Export data as ${selectedOption.lable} for Asset list`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption,
                                }}
                                openImport={this.openImport}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                                searchKey={this.search}
                                savedAdvancedFilter={this.advanceFilterData}
                            />
                            :
                            <div style={{ height: '100%' }}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                    </div>
                    {openImport &&
                        <ImportComponent closeImport={this.closeImport} updateLoader={this.updateLoader} importType="Assets" />
                    }
                </MiniDrawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    assets: state.assetList.assets,
    totalAssets: state.assetList.totalAssets,
    isUpdating: state.assetList.isUpdating,
    deviceFeature: state.spudMessage.deviceFeature,
});

export default withStyles(themeStyles)(reduxConnect(AssetList, actions, mapStateToProps));
