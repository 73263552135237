/* @flow */
import { combineEpics } from 'redux-observable';
import {
    FETCH_LANDMARKS,
    FETCH_ASSET_IN_LANDMARK,
} from './constants.landmarks';
import {
    fetchLandmarksSuccess,
    fetchLandmarksError,
    fetchAssetInLandmarkSuccess,
    fetchAssetInLandmarkError,
} from './actions.landmarks';
import config from '../../../constants/Config';
import { normalizePhoneNumber, formatLandmarkAddress as formatAddress } from '../../../helper-classes/utility-functions';
import { mapColor } from '../../constants.map';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

export const getShapeData = (data: Object) => {
    const type = (data.shape) ? data.shape.toLowerCase() : '';
    switch (type) {
    case 'rectangle': return {
        type: 'RECTANGLE', point1: { lat: data.box.first.y, lng: data.box.first.x }, point2: { lat: data.box.second.y, lng: data.box.second.x },
    };
    case 'circle': return {
        type: 'CIRCLE', radius: data.circle.radius.value || 400, lat: parseFloat(data.circle.center.y), lng: parseFloat(data.circle.center.x),
    };
    case 'polygon': return {
        type: 'POLYGON', points: data.polygon.points.map(d => ({ lat: d.y, lng: d.x })),
    };
    default: return '';
    }
};

export const processLandmarkMarkers = (items: Object, redirectTo: String) => items.map((d) => {
    const newData = d;
    newData.latitude = d.marker.y || 0;
    newData.longitude = d.marker.x || 0;
    newData.lat = d.marker.y || 0;
    newData.lng = d.marker.x || 0;
    newData.redirectTo = redirectTo;
    newData.shapeData = getShapeData(d);
    newData.markerType = 'landmark';
    newData.color = mapColor.cluster.landmarkColor;
    return newData;
});

const formatData = data => ({
    id: data.id || '',
    type: data.type || '',
    landmarkName: data.name || '',
    asset: `${data.totalAssets} Assets` || '',
    address: formatAddress(data),
    phone: normalizePhoneNumber(data.phoneNumber) || '',
    group: data.landmarkGroupName || '',
    state: data.state || '',
    city: data.city || '',
    landmarkDetails: {
        name: data.name || '',
        address: formatAddress(data),
        lat: (data.marker) ? data.marker.y : 0,
        lng: (data.marker) ? data.marker.x : 0,
        shape: getShapeData(data),
    },
    customeAttributes: {
        inState: data.inState || '',
        repairs: data.repairs || '',
        towable: data.towable || '',
        contact: data.contact || '',
    },
    markerLat: data.markerLat || 0,
    markerLng: data.markerLng || 0,
});

let landMarkData = {};

export const getAssetsInLandmarkRequestURL = (id) => `${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${id}/assets`;

export const getAssetsInLandmarkRequestBody = (data) => {
    const reqData = {
        start: data.currentPage * data.recordsPerPage,
        limit: data.recordsPerPage,
        type: 'landmarkId',
        searchParams: [data.id],
    };
    if (data.cargoLoadedQuery) {
        // eslint-disable-next-line
        reqData['filterParam'] = [['cargoLoaded', data.cargoLoadedQuery]];
    }
    return reqData;
}

export const getLandmarks =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_LANDMARKS)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const { id, assetInLandmarkfilter } = action.payload;
                return getJSON(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/${id}?cb=${new Date().getTime()}`, headers).mergeMap((res) => {
                    if (!res.data) {
                        landMarkData = formatData({});
                    } else {
                        landMarkData = formatData(res.data[0]);
                    }
                    return of(fetchLandmarksSuccess({ ...landMarkData, assetInLandmarkfilter }));
                }).catch(error => of(fetchLandmarksError(error)));
            });

export const fetchAssetInLandmark =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_ASSET_IN_LANDMARK)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                    'Content-Type': 'application/json',
                };
                const { id, assetInLandmarkfilter } = action.payload;
                const reqData = getAssetsInLandmarkRequestBody({ ...assetInLandmarkfilter, id });
                return postJSON(getAssetsInLandmarkRequestURL(id), reqData, headers)
                    .mergeMap((res) => {
                        const { response } = res || { data: [], total: 0, count: 0 };
                        return of(fetchAssetInLandmarkSuccess(response))
                    })
                    .catch(error => of(fetchAssetInLandmarkError(error)));
            });

export default combineEpics(getLandmarks, fetchAssetInLandmark);
