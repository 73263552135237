/* @flow */
import React, { Component } from 'react';
import { Typography, Menu, MenuItem, ListItemText, Checkbox } from '@mui/material';
import AppLoader from './../../components/AppLoader';


export type Props = {
    open: boolean,
    anchorEl: HTMLElement,
    handleClose: Function,
    handleCheckBoxChange: Function,
    loaded: boolean,
    unLoaded: boolean,
    getCargoAssetCount: Function,
};

export type State = {
    loadedCount: number,
    unLoadedCount: number,
    showLoadedLoader: boolean,
    showUnLoadedLoader: boolean,
};



class CargoMenu extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            loadedCount: 0,
            unLoadedCount: 0,
            showLoadedLoader: true,
            showUnLoadedLoader: true,
        }
    }

    componentDidMount() {
        this.props.getCargoAssetCount(true).then((response) => {
            this.setState({
                loadedCount: response.total,
                showLoadedLoader: false,
            });
        });
        this.props.getCargoAssetCount(false).then((response) => {
            this.setState({
                unLoadedCount: response.total,
                showUnLoadedLoader: false,
            });
        });
    }

    getListItem = (text, showLoader, count) => (
        <ListItemText sx={{ pl: 1, pr: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{text}</div>
                <div style={{ paddingLeft: 4 }}>
                    {showLoader ?
                        <AppLoader height={20} width={3} /> :
                        <span>({count})</span>
                    }
                </div>
            </div>
        </ListItemText>
    )

    getCheckbox = (checked, handleChange, type) => (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            <Checkbox
                size="small"
                disableRipple
                onChange={(e) => handleChange(e, type)}
                checked={checked}
            />
        </Typography>
    )

    render() {
        const {
            open,
            anchorEl,
            handleClose,
            handleCheckBoxChange,
            loaded,
            unLoaded,
        } = this.props;

        const { showLoadedLoader, showUnLoadedLoader, loadedCount, unLoadedCount } = this.state;

        return (
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                defaultValue="Available_Assets"
            >
                <MenuItem
                    onClick={handleClose}
                    value="loaded"
                    sx={{ pt: 0 }}
                >
                    {this.getListItem('Loaded', showLoadedLoader, loadedCount)}
                    {this.getCheckbox(loaded, handleCheckBoxChange, 'loaded')}
                </MenuItem>
                <MenuItem
                    onClick={handleClose}
                    value="unloaded"
                    sx={{ pt: 0 }}
                >
                    {this.getListItem('Unloaded', showUnLoadedLoader, unLoadedCount)}
                    {this.getCheckbox(unLoaded, handleCheckBoxChange, 'unLoaded')}
                </MenuItem>
            </Menu>
        );
    }
}

export default CargoMenu;
