/* flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Stepper, Step, StepLabel, Grid, Typography, TextField, Divider } from '@mui/material';
import styles from './PlansStepper.module.scss';
import AssetCriteria from '../SharedComponents/AssetCriteria/AssetCriteria';
import ServiceInterval from '../SharedComponents/ServiceInterval/ServiceInterval';
import AlertRecipientList from '../../containers/Alerts/AlertRecipient/AlertRecipientList';
import { getDistributionList, getFVSAssets, getGrailsServicePlan } from '../../containers/Maintenance/helper-classes/common-services';

type Props = {
    onPlanChanged: Function,
    activeStep: number,
    selectedPlan: Array<{}>,
    editMode: boolean,
    setApiDistanceInterval: Function,
};

type State = {
    servicePlanName: string,
    servicePlanDescription: string,
    serviceInterval: any,
    assetCriteria: any,
    recipientsList: any,
    descriptionError: string,
}

const stepstyles = theme => ({
    container: {
        width: '75%',
        height: 700,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    instructions: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
});

function getSteps() {
    return [{ label: 'Asset Criteria' }, { label: 'Service Plan' }, { label: 'Recipients', subText: 'Optional' }];
}

class PlansStepper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { editMode, selectedPlan } = props;
        this.state = {
            servicePlanName: editMode && selectedPlan ? selectedPlan.name : '',
            servicePlanDescription: editMode && selectedPlan ? selectedPlan.description : '',
            serviceInterval: null,
            descriptionError: '',
        };
    }

    componentDidMount() {
        const { selectedPlan } = this.props;
        if (selectedPlan) {
            getDistributionList(selectedPlan.id).then(recipients => (
                this.setState({ recipientsList: recipients })
            ));
            if (this.props.editMode) {
                this.validateDescription(this.state.servicePlanDescription);
            }
        }
        if (this.props.editMode) {
            const assetCriteria = { tags: [], groups: [], assets: [] };
            const {
                assetTags, assetIds, assetGroupIds, id,
            } = selectedPlan;
            assetCriteria.tags = assetTags;
            if (assetIds && assetIds.length > 0) {
                assetCriteria.assets.push(' '); // append empty asset for UI to not show all
                getFVSAssets(assetIds).then((data) => {
                    if (data && data.length > 0) {
                        assetCriteria.assets = data.map(c => c.name);
                        this.setState(assetCriteria);
                    }
                });
            }

            if (assetGroupIds) {
                getGrailsServicePlan(id).then((response) => {
                    if (response && response.data) {
                        const plan = response.data[0];
                        if (plan) this.props.setApiDistanceInterval(plan.distanceInterval);
                        if (plan && plan.assetGroups) {
                            assetCriteria.groups = plan.assetGroups.map(g => g.name);
                            this.setState(assetCriteria);
                        }
                    }
                });
            }

            setTimeout(() => {
                this.setState({ assetCriteria });
            }, 300);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state !== prevState) this.props.onPlanChanged(this.state);
    }

    handleChange = name => event => this.setState({ [name]: event.target.value });

    validateDescription = (description) => {
        const unsupportedCharactersList = description.match(/[<>&]/g);
        if (unsupportedCharactersList) {
            const uniqueUnsupportedCharacters = [...new Set(unsupportedCharactersList)].join(' ');
            this.setState({
                descriptionError: `Invalid input characters found: ${uniqueUnsupportedCharacters}`,
            });
        } else {
            this.setState({ descriptionError: '' });
        }
    }

    handleDescriptionChange = () => (event) => {
        const description = event.target.value;
        this.validateDescription(description);
        this.setState({ servicePlanDescription: description });
    };

    onAssetCriteriaChanged = assetCriteria => this.setState({ assetCriteria });

    onRecipientListChanged = recipientsList => this.setState({ recipientsList });

    onServiceIntervalChanged = serviceInterval => this.setState({ serviceInterval });

    getStepContent = (step) => {
        const { recipientsList } = this.state;
        switch (step) {
        case 0:
            return (
                <div className={styles['step-content']}>
                    <AssetCriteria
                        assetCriteria={this.state.assetCriteria}
                        onAssetCriteriaChanged={this.onAssetCriteriaChanged}
                        detailsOnlyMode={this.props.editMode}
                        assetFilter={[['deviceId', 'exists:yes']]}
                    />
                </div>
            );
        case 1:
            return (
                <div className={styles['step-content']}>
                    <Grid container>
                        <Grid item xs={6} className={styles['first-col']}>
                            <Typography className={styles['main-label']}>Name *</Typography>
                            <TextField
                                id="service-plan-name"
                                placeholder="Enter Name"
                                className={styles['main-field']}
                                value={this.state.servicePlanName}
                                onChange={this.handleChange('servicePlanName')}
                                margin="normal"
                            />
                            <Typography className={styles['main-label']}>Description</Typography>
                            <TextField
                                id="service-plan-description"
                                placeholder="Enter Service Description"
                                className={styles['main-field']}
                                value={this.state.servicePlanDescription}
                                onChange={this.handleDescriptionChange()}
                                margin="normal"
                                helperText={this.state.descriptionError || ''}
                                error={Boolean(this.state.descriptionError)}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 20 }}>
                            <ServiceInterval
                                onServiceIntervalChanged={this.onServiceIntervalChanged}
                                selectedPlan={this.props.selectedPlan}
                                serviceInterval={this.state.serviceInterval}
                                editMode={this.props.editMode}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        case 2:
            return (
                <div className={styles['step-content']}>
                    <Typography className={styles['main-label']}>
                        Add Recipient
                    </Typography>
                    <AlertRecipientList
                        key={`key_AlertRecipientList_${recipientsList ? recipientsList.length : 0}`}
                        hideComments
                        recipientData={this.state.recipientsList}
                        formData={{}}
                        getRecipientJson={this.onRecipientListChanged}
                    />
                </div>
            );
        default:
            return <div className={styles['step-content']} />;
        }
    }

    render() {
        const steps = getSteps();
        const { activeStep } = this.props;
        const subTextStyle = {
            fontSize: 10,
            width: '100%',
            textAlign: 'center',
        };
        return (
            <div>
                <Stepper activeStep={activeStep}>
                    {
                        steps.map((label) => {
                            const subText = label.subText &&
                                <div style={subTextStyle}>{label.subText}</div>;
                            return (
                                <Step key={label.label}>
                                    <StepLabel>
                                        {label.label}
                                        {subText}
                                    </StepLabel>
                                </Step>
                            );
                        })
                    }
                </Stepper>
                <Divider className={styles['divider-shadow']} />
                {this.getStepContent(activeStep)}
            </div>
        );
    }
}

export default withStyles(stepstyles)(PlansStepper);
