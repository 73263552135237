/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { ListItem, Grid, Avatar, Typography, Tooltip } from '@mui/material';
import customStyles from './LandmarkDetails.module.scss';
import { getAssetColorFromStatus } from '../../util/trip_util';
import {
    shortTimeDifference,
    convertDateTime,
    roundOffUnitValueAsPerUserSetting,
    getSpeedUnitString,
    getAssetStatusStartDate,
    isPairedAsset,
    isAssetNonReporting,
    getTimeStr,
    getTimeDiff,
    getNonReportingToolTip,
    isBoolean,
} from '../../helper-classes/utility-functions';
import GetSvgIcon, { AssetNonReporting } from '../../util/svgImage_util';
import { getAssetIconType, hoverIcon, haloEffectTimeout } from '../../util/map_utils';

let height = 0;
if (document.documentElement) {
    height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

type Props = {
    asset: Object,
    landmarkId: string,
}

const styles = theme => ({
    root: {
        height: height - 348,
        width: '100%',
        overflow: 'auto',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
    },
});

function avatarHelper(type) {
    const parsedType = type.split('.').slice(-1)[0];

    if (parsedType === 'heavy') {
        return 'truck';
    } else if (parsedType === 'equipment') {
        return 'equipment';
    } else if (parsedType === 'trailer') {
        return 'trailer';
    }
    return 'vehicle'; // light & default avatar
}

function statusHelper(assetStatus) {
    return assetStatus.charAt(0).toUpperCase() + assetStatus.substr(1);
}

const landmarkEventDate = (asset, landmarkId) => {
    if (asset && asset.landmarkEventDates) {
        const lmIndex = asset.landmarkIds.indexOf(landmarkId);
        if (lmIndex !== -1 && asset.landmarkEventDates.length >= lmIndex) {
            return new Date(asset.landmarkEventDates[lmIndex]).toString();
        }
    }
    return '';
};

const arrivalTime = (arrivalDate: string) => (arrivalDate ? convertDateTime(arrivalDate, 'MM/DD/YYYY') : 'N/A');

const dwellTime = (arrivalDate: string) => (arrivalDate ? shortTimeDifference(new Date(), new Date(arrivalDate)) : 'N/A');

function AssetListItem(props : Props) {
    const { asset, landmarkId } = props;
    const statusDuration = getAssetStatusStartDate(asset);
    let isHoverIconCalled = false;
    const isNonReporting = isAssetNonReporting(asset);
    let lastReporting = '';

    if (isNonReporting) {
        lastReporting = asset.lastEventDate ? `last report ${getTimeStr(getTimeDiff(asset.lastEventDate, new Date()))} ago` : 'never reported';
    }

    const haloEffect = (isHover: boolean) => {
        isHoverIconCalled = isHover;
        hoverIcon(asset, isHover);
    };

    return (
        <React.Fragment>
            <ListItem
                button
                className={customStyles['list-item']}
                onMouseEnter={() => haloEffectTimeout(() => haloEffect(true))}
                onMouseOver={() => !isHoverIconCalled && haloEffectTimeout(() => haloEffect(true))}
                onFocus={() => {}}
                onMouseLeave={() => haloEffectTimeout(() => haloEffect(false))}
            >
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        <React.Fragment>
                            {isNonReporting &&
                                <React.Fragment>
                                    <AssetNonReporting
                                        className={customStyles.tooltip}
                                    />
                                    <span className={customStyles.tooltipText}>
                                        {getNonReportingToolTip(asset)}
                                    </span>
                                </React.Fragment>
                            }
                            <Avatar style={{ backgroundColor: getAssetColorFromStatus(asset) }}>
                                <i
                                    className={customStyles[`${avatarHelper((asset.type) ? asset.type.toLowerCase() : '')}`]}
                                />
                                <GetSvgIcon
                                    type={asset.attributes.icon
                                        ? getAssetIconType(asset.attributes.icon)
                                        : getAssetIconType(asset.typeCode)}
                                />
                            </Avatar>
                            {isBoolean(asset.cargoLoaded) &&
                                <span
                                    className={customStyles.cargo_status}
                                    style={{
                                        opacity: asset.cargoLoaded ? 1 : 0.5,
                                    }}
                                >
                                    {asset.cargoLoaded ? 'Loaded' : 'Unloaded'}
                                </span>
                            }
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={0}>
                            <Grid item xs={7}>
                                <div className={customStyles['asset-name-width']}>
                                    <Typography className={customStyles['asset-name']} component="p">
                                        {asset.name}
                                    </Typography>
                                    {isPairedAsset(asset) ?
                                        <div className={customStyles['movement-and-last-location']}>
                                            {isNonReporting ?
                                                <React.Fragment>
                                                    <span className={customStyles[`${statusHelper(asset.status)}`]}>
                                                        {statusHelper(asset.status)}
                                                    </span>
                                                    <Tooltip title={lastReporting || ''}>
                                                        <span
                                                            className={
                                                                customStyles.last_reporting_text
                                                            }
                                                        >
                                                            &nbsp;({lastReporting})
                                                        </span>
                                                    </Tooltip>
                                                </React.Fragment>
                                                :
                                                <Typography
                                                    className={customStyles[`${statusHelper(asset.status)}`]}
                                                    component="p"
                                                >
                                                    {asset.status ?
                                                        `${statusHelper(asset.status)} ${statusDuration && ` for ${statusDuration}`}`
                                                        : '-- --'
                                                    }
                                                </Typography>
                                            }
                                            <Typography className={customStyles['last-location']} component="p">
                                                Arrival: {`${arrivalTime(landmarkEventDate(asset, landmarkId))}`} ({`${dwellTime(landmarkEventDate(asset, landmarkId))}`})
                                            </Typography>
                                        </div>
                                        :
                                        <div className={customStyles['movement-and-last-location']}>
                                            <div className={customStyles['last-location']}>
                                                This asset is not currently connected to a device
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={customStyles.asset_speed_dir_container}>
                                    {(asset.speed != null) ?
                                        <Typography paragraph className={customStyles.speed_dir_txt} align="right" component="p">
                                            {`${roundOffUnitValueAsPerUserSetting(asset.speed || 0)} ${getSpeedUnitString()}`}
                                        </Typography>
                                        :
                                        <Typography paragraph align="right" component="p" />
                                    }
                                    <div className={customStyles.asset_dir_container}>
                                        {asset.direction &&
                                            <React.Fragment>
                                                <i className={customStyles[`${asset.direction}_dir`]} />
                                                <Typography className={customStyles.speed_dir_txt} align="right" component="p">
                                                    {asset.direction}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            <div className={customStyles['list-border']} />
        </React.Fragment>
    );
}

export default withStyles(styles)(AssetListItem);
